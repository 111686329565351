// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/Users/lawjeremy/www/jeremylaw.ca-gatsby/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/Users/lawjeremy/www/jeremylaw.ca-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adventure-js": () => import("/Users/lawjeremy/www/jeremylaw.ca-gatsby/src/pages/adventure.js" /* webpackChunkName: "component---src-pages-adventure-js" */),
  "component---src-pages-art-js": () => import("/Users/lawjeremy/www/jeremylaw.ca-gatsby/src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-game-dev-js": () => import("/Users/lawjeremy/www/jeremylaw.ca-gatsby/src/pages/game-dev.js" /* webpackChunkName: "component---src-pages-game-dev-js" */),
  "component---src-pages-index-js": () => import("/Users/lawjeremy/www/jeremylaw.ca-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-web-dev-js": () => import("/Users/lawjeremy/www/jeremylaw.ca-gatsby/src/pages/web-dev.js" /* webpackChunkName: "component---src-pages-web-dev-js" */)
}

